<template>
    <div class="content">
        <!-- 托管服务-核查记录- 按天-->
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-wrapper"
            >
                <expand-filter
                    :formData="formData"
                    :closeWidth="360"
                    marginBottom="10px"
                    borderRadius="0px 4px 4px 4px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @datePickerBlur="datePickerBlur"
                ></expand-filter>
                <div class="table-paging">
                    <table-data
                        :config="table_config"
                        :tableData="table_data"
                        v-loading="loadingTable"
                        backgroundHeader="#FAFBFC"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        ref="table"
                        id="table"
                    >
                        <template #checkDate="{data}">
                            <span
                                style="color: #3C7FFF; cursor: pointer"
                                @click="handleDetails(data)"
                                >{{ data.checkDate }}</span
                            >
                        </template>
                        <template v-slot:operation="slotData">
                            <xk-button
                                v-hasPermi="['checkRecordDay:edit']"
                                size="mini"
                                @click="handelEdit(slotData.data)"
                                style="
                                    color: #3C7FFF;
                                    border: none;
                                    background: none;
                                "
                            >
                                编辑
                            </xk-button>
                            <xk-button
                                v-hasPermi="['checkRecordDay:delete']"
                                size="mini"
                                style="color: #f26231; border: none;background: none;"
                                @click="handelDetele(slotData.data)"
                            >
                                删除
                            </xk-button>
                        </template>
                    </table-data>
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList(true)"
                    />
                </div>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    Loading,
    MTreeSelect,
    Pagination,
    DialogWrapper,
} from "common-local";
import ExpandFilter from "../../Sub/ExpandFilter.vue";
import TableData from "../../Sub/TableData.vue";
import selectTree from "../../Sub/selectTree";
import {mapState} from "vuex";
import {CheckRecordModel} from "@/models/CheckRecord";
import RecordsList from '@/libs/pageListOptimal.js';
import onResize from "@/mixins/onResize"
export default {
    name: "DataList",
    mixins: [onResize],
    components: {
        selectTree,
        Loading,
        TableData,
        Pagination,
        MTreeSelect,
        ExpandFilter,
        DialogWrapper,
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                startTime: "",
                endTime: "",
                checkStatus:1,//核查状态 1已核查 0未核查
                jobScope:[2,3],

            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "daterange",
                        // dateType: "datetimerange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["checkRecordDay:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "日期",
                        type: "slot",
                        slotName: "checkDate",
                        fixed: false,
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "应到人数",
                        prop: "shouldNum",
                        align: 'center'
                    },
                    {
                        label: "实到人数",
                        prop: "realityNum",
                        align: 'center'
                    },
                    {
                        label: "提交人",
                        prop: "createName",
                        align: 'center'
                    },
                    {
                        label: "提交时间",
                        prop: "createTime",
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            total: 0,
        };
    },
    created() {
        this.init();
        this.getList();
    },
    mounted() {
        setTimeout(() => {
            this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 138;
        },50)
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    activated(){
        this.setTableScrollHeight();
    },
    methods: {
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-07 16:55:10
         */
        init() {
            this.setPageInfo();
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name)
                },200)
            }
        },
        /**
         * @Description: 日期选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 09:22:23
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value ? dateArr.value : ["", ""];
            this.listQuery.startTime = startTime;
            this.listQuery.endTime = endTime;
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:11:20
         */
        getList(isPaging) {
            this.loadingTable = true;
            this.$forceUpdate();
            if (!isPaging) {
                this.listQuery.pageNum = 1;
            }
            const checkRecordModel = new CheckRecordModel();
            checkRecordModel.getCheckRecordDataList(this.listQuery).then((res) => {
                if (res.data.code === "200") {
                    this.total = res.data.data.totalCount;
                    this.table_data = res.data.data.list;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            }).catch(err => {
                this.loadingTable = false;
            })
        },
        /**
         * @Description: 详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 10:46:43
         */
        handleDetails(data) {
            this.$eventDispatch('setGlobalPageType', 'form')
            this.savePageInfo();
            this.$eventDispatch("toDetail", {
                typeId: "data",
                type: "info",
                id: data.id,
            });
        },
        /**
         * @Description: 删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 09:57:29
         */
        handelDetele(data) {
            this.$confirm('删除之后需要重新核查，并且不可恢复，是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const checkRecordModel = new CheckRecordModel();
                checkRecordModel.getCheckRecordDelete({ id: data.id }).then((res) => {
                    if (res.data.code === '200') {
                        this.$message.success('删除成功')
                        this.getList()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 编辑
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 09:57:41
         */
        handelEdit(data) {
            this.$eventDispatch('setGlobalPageType', 'form')
            this.$eventDispatch("toDetail", {
                typeId: "data",
                type: "edit",
                id: data.id,
            });
        },
        /**
         * @Description: 查询、重置、导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 17:06:20
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset": //重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.listQuery.startTime = '';
                    this.listQuery.endTime = '';
                    this.getList(1);
                    break;
                case "export": //导出
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    .content-wrapper {
        border-radius: 0px;
    }
    .content-wrapper .el-date-editor {
        width: 340px !important;
    }

    .table-paging {
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }
}
/deep/ .el-select {
    width: 100% !important;
}
// .content-wrapper .expand-filter{
//     margin-bottom: 10px !important;
//     border-radius: 0px 4px 4px 4px !important;
// }
</style>
