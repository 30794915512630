<template>
    <div class="content">
        <!-- 托管服务-核查记录- 按学生-->
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-wrapper"
            >
                <expand-filter
                    :formData="formData"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    :closeWidth="1119"
                    @changeSel="changeSel"
                    @datePickerBlur="datePickerBlur"
                    marginBottom="10px"
                    borderRadius="0px 4px 4px 4px"
                ></expand-filter>
                <div class="total-data">合计托管天数:{{ allDay }}天</div>
                <div class="table-paging">
                    <table-data
                        :config="table_config"
                        :tableData="table_data"
                        v-loading="loadingTable"
                        backgroundHeader="#FAFBFC"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        ref="table"
                        id="table"
                    >
                        <template #actualDay="{data}">
                            <span
                                style="color: #3C7FFF; cursor: pointer"
                                @click="handleDetails(data)"
                                >{{ data.actualDay }}</span
                            >
                        </template>
                    </table-data>
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList(true)"
                    />
                </div>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    Loading,
    MTreeSelect,
    Pagination,
    DialogWrapper,
} from "common-local";
import ExpandFilter from "../../Sub/ExpandFilter.vue";
import TableData from "../../Sub/TableData.vue";
import selectTree from "../../Sub/selectTree";
import {mapState} from "vuex";
import {getToken} from "@/libs/auth";
import {downloadFile} from "@/libs/utils";
import {CheckRecordModel} from "@/models/CheckRecord";
import {CustodialServicesModel} from "@/models/CustodialServices";
import RecordsList from '@/libs/pageListOptimal.js';
import onResize from "@/mixins/onResize"
import exportReactive from '@/libs/exportReactive.js';
export default {
    name: "StudentList",
    mixins: [onResize],
    components: {
        selectTree,
        Loading,
        TableData,
        Pagination,
        MTreeSelect,
        ExpandFilter,
        DialogWrapper,
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            allDay: "",
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                studentName: "",
                onlyCode: "",
                studyNo: "",
                gradeId: "",
                classId: "",
                startTime: "",
                endTime: "",
                jobScope:[2,3],
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "班级",
                        key: "classId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入姓名",
                        key: "studentName",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入唯一号",
                        key: "onlyCode",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入学号",
                        key: "studyNo",
                    },
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "datetimerange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["checkRecordStudent:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "enquiry",
                        text: "导出",
                        fn: "export",
                        auth: ["checkRecordStudent:export"],
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        prop: "studentName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: 'center'
                    },
                    {
                        label: "实际托管天数（天）",
                        type: "slot",
                        slotName: "actualDay",
                        align: 'center'
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            total: 0,
        };
    },
    created() {
        this.init();
        this.getList();
    },
    activated(){
        this.setTableScrollHeight();
    },
    mounted() {
        setTimeout(() => {
            this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 138;
        },50)
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    methods: {
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-07 16:55:10
         */
        init() {
            this.setDefaultTime();
            this.setPageInfo();
            this.getTotalData();
            this.getGradList();
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name)
                },150)
            }
        },
        /**
         * @Description: 设置默认时间
         * @DoWhat:
         * @UseScenarios: 默认显示当月
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 15:55:37
         */
        setDefaultTime() {
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    let startDate = this.$moment()
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    let currentDate = this.$moment()
                        .endOf("month")
                        .format("YYYY-MM-DD");
                    item.value = [startDate, currentDate];
                }
            });
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:11:20
         */
        getList(isPaging) {
            this.loadingTable = true;
            this.$forceUpdate();
            let data = {};
            if (!isPaging) {
                this.listQuery.pageNum = 1;
            }
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });

            if (data.dateArr && data.dateArr.length > 0) {
                this.listQuery.startTime = data.dateArr[0] + " 00:00:00";
                this.listQuery.endTime = data.dateArr[1] + " 23:59:59";
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
            const checkRecordModel = new CheckRecordModel();
            checkRecordModel
                .getCheckRecordStudentList(this.listQuery)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.total = res.data.data.total;
                        this.table_data = res.data.data.rows;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                }).catch(err => {
                    this.loadingTable = false;
                })
        },
        /**
         * @Description: 合计托管天数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 11:00:52
         */
        getTotalData() {
            let data = {};
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            if (data.dateArr && data.dateArr.length > 0) {
                this.listQuery.startTime = data.dateArr[0] + " 00:00:00";
                this.listQuery.endTime = data.dateArr[1] + " 23:59:59";
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
            const checkRecordModel = new CheckRecordModel();
            checkRecordModel.getAllDay(this.listQuery).then((res) => {
                if (res.data.code === "200") {
                    this.allDay = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 10:46:43
         */
        handleDetails(value) {
            this.$eventDispatch('setGlobalPageType', 'form')
            this.savePageInfo();
            let data = {};
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            this.$eventDispatch("toDetail", {
                typeId: "student",
                value: value,
                dateArr: data.dateArr,
            });
        },
        /**
         * @Description: 查询、重置、导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-10 17:06:20
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    console.log(
                        this.listQuery.startTime,
                        " this.listQuery.startTime",
                    );
                    let status = this.checkTime(
                        this.listQuery.startTime,
                        this.listQuery.endTime,
                    );
                    console.log(status, "status00000000000000");
                    if (status) {
                        this.getList(1);
                        this.getTotalData();
                    }
                    break;
                case "reset": //重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.setDefaultTime();
                    this.getTotalData();
                    this.getList(1);
                    break;
                case "export": //导出
                    exportReactive(this.formData.btnList, 'export', true)
                    this.getExportData();
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 选择时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-14 16:25:31
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value ? dateArr.value : ["", ""];
            this.checkTime(startTime, endTime);
            this.listQuery.startTime = startTime;
            this.listQuery.endTime = endTime;
        },
        /**
         * @Description: 时间校验，不能超过6个月
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-14 16:25:09
         */
        checkTime(startTime, endTime) {
            let start = new Date(startTime);
            let end = new Date(endTime);
            let status = true;
            let endFullYear = end.getFullYear();
            let startFullYear = start.getFullYear();
            let endMonth = end.getMonth();
            let startMonth = start.getMonth();

            if (endFullYear != startFullYear) {
                if (endMonth + 12 - startMonth > 6) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    status = false;
                }

                if (endMonth + 12 - startMonth == 6) {
                    if (end.getDate() > start.getDate()) {
                        this.$message.error("开始时间和结束时间不能超过6个月");
                        status = false;
                    }
                }
            }
            if (end.getMonth() - start.getMonth() > 6) {
                this.$message.error("开始时间和结束时间不能超过6个月");
                console.log("33333");
                return (status = false);
            } else if (end.getMonth() - start.getMonth() == 6) {
                if (end.getDate() > start.getDate()) {
                    console.log("44444");
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    return (status = false);
                }
            }
            return status;
        },
        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 11:30:21
         */
        getExportData() {
            let data = {};
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            if (data.dateArr && data.dateArr.length > 0) {
                this.listQuery.startTime = data.dateArr[0] + " 00:00:00";
                this.listQuery.endTime = data.dateArr[1] + " 23:59:59";
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
            downloadFile(
                {
                    url: "/school/schoolCheckStudent/export",
                    form: this.listQuery,
                },
                () => {
                    exportReactive(this.formData.btnList, 'export', false)
                    this.$message.success("导出成功");
                },
                () => {},
            );
        },
        /**
         * @Description: 获取年级下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-12 11:30:05
         */
        getGradList() {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .getYearDropDownList({
                    schoolId: this.$store.state.schoolId,
                    jobScope: ["2", "3"],
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        console.log(res, "0000");
                        this.formData.data.forEach((item) => {
                            if (item.key === "gradeId") {
                                item.list = res.data.data.map((i) => ({
                                    label: i.name,
                                    value: i.id,
                                }));
                                // item.list.unshift({ label: '全部', value: '' })
                            }
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 获取班级下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-12 11:57:19
         */
        getClassList(gradeId) {
            const classIndex = this.formData.data.findIndex(
                (i) => i.key === "classId",
            );
            this.formData.data[classIndex].value = "";
            this.formData.data[classIndex].list = [];
            if (gradeId) {
                const custodialServicesModel = new CustodialServicesModel();
                custodialServicesModel
                    .getClassDropDownList({
                        schoolId: this.$store.state.schoolId,
                        gradeId: gradeId,
                        jobScope: ["2", "3"],
                    })
                    .then((res) => {
                        if (res.data.code === "200") {
                            this.formData.data.forEach((item) => {
                                if (item.key === "classId") {
                                    item.list = res.data.data.map((i) => ({
                                        label: i.name,
                                        value: i.id,
                                    }));
                                }
                            });
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
            }
        },
        /**
         * @Description: 修改年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 10:56:37
         */
        changeSel(data) {
            if (data.key === "gradeId") {
                this.getClassList(data.value);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    .content-wrapper .el-date-editor {
        width: 340px !important;
    }
    /deep/ .expand-filter {
    }
    .total-data {
        height: 36px;
        line-height: 36px;
        background: #ffffff;
        padding-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2b2f33;
        font-weight: bold;
    }
    .table-paging {
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }
}
/deep/ .el-select {
    width: 100% !important;
}
</style>
